import React, { useState, useEffect } from 'react';
import Lightbox from './LightBoxCustom.js';
import { Link } from 'react-router-dom';

function ImageCarousel() {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [columns, setColumns] = useState(3);


  const isLocal = process.env.NODE_ENV !== "production";

  const IMGserver = isLocal
  ? "http://localhost:5000/api/images"
  : "https://server.bs24.ee/api/images";



  useEffect(() => {
    // Fetch images from the API
  
    const IMGserver = isLocal
    ? "http://localhost:5000/api/images"
    : "https://server.bs24.ee/api/images";


    fetch(IMGserver)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => setImages(data))
      .catch(error => {
        console.error('Error fetching images:', error);
        setError(error.message);
      });



    

  }, []);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setColumns(1);
      } else if (width < 1200) {
        setColumns(3);
      } else if (width > 2000) {
        setColumns(6);
      } else {
        setColumns(4);
      }
    };

    updateColumns();
   
    window.addEventListener('resize', updateColumns);

    return () => {
      window.removeEventListener('resize', updateColumns);
    };
  }, []);

  useEffect(() => {
    let listenersAttached = false;
  
    const handleLightboxClick = (event) => {
      event.preventDefault();
      const options = {
        keyboard: true,
        size: 'fullscreen',
        constrain: true
      };
      new Lightbox(event.currentTarget, options).show();
    };
  
    const initLB = () => {


      // Clean up event listeners when the component unmounts
      document.querySelectorAll('[data-toggle="lightbox"]').forEach(el => {
        el.removeEventListener('click', handleLightboxClick);
      });
  
      listenersAttached = false; // Reset flag when component unmounts

      // Initialize the Lightbox after images have loaded and DOM is updated
      if (images.length > 0 && !listenersAttached) {
        try {
          console.log("Initializing Lightbox");
  
          document.querySelectorAll('[data-toggle="lightbox"]').forEach(el => {
            // Remove any existing event listener first to avoid compounding
            el.removeEventListener('click', handleLightboxClick);
            // Add the event listener
            el.addEventListener('click', handleLightboxClick);
          });
  
          listenersAttached = true; // Set flag to true after attaching listeners
        } catch (error) {
          console.error('Lightbox initialization error:', error);
        }
      }
    };
  
    initLB();
  
    window.addEventListener('resize', initLB);
  
    return () => {
      window.removeEventListener('resize', initLB);
  

    };
  
  }, [images]);

  

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (images.length === 0) {
    return <p>Loading...</p>;
  }

  const parseFilename = (filename) => {
    let nameWithoutExtension = filename.replace(/\.[^/.]+$/, "");
    nameWithoutExtension = nameWithoutExtension.replace(/\d+$/, "");
    nameWithoutExtension = nameWithoutExtension.charAt(0).toUpperCase() + nameWithoutExtension.slice(1);
    return nameWithoutExtension.replace(/-/g, " ").trim();
  };

  const carouselItems = [];
  for (let i = 0; i < images.length; i += columns) {
    carouselItems.push(images.slice(i, i + columns));
  }

  return (
    <div id="carousel_bs241" className="carousel slide">
      <div className="carousel-indicators">
        {carouselItems.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carousel_bs24"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current={index === 0 ? 'true' : 'false'}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {carouselItems.map((group, groupIndex) => (
          <div key={groupIndex} className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`}>
            <div className="row g-0">
              {group.map((image, imageIndex) => (
                <div key={imageIndex} className={`col-${Math.floor(12 / columns)} position-relative`}>
                  {image ? (
                    <a href={`/site/photos/${image.category}/${image.file}`} 
                    data-toggle="lightbox"
                   
                       >
                      <img
                        src={`/site/thumbnails/${image.category}_${image.file}`}
                        className="d-block w-100 img-fluid"
                        alt={`Slide ${groupIndex * columns + imageIndex + 1}`}
                       
                      />
                      <div className="carousel-caption d-block text-light">
                        <p>{`${parseFilename(image.category)}`}</p>
                      </div>
                    </a>
                  ) : (
                    <div className="d-block w-100 img-fluid"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev carousel-nav"
        type="button"
        data-bs-target="#carousel_bs24"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon " aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next carousel-nav"
        type="button"
        data-bs-target="#carousel_bs24"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon carousel-nav" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default ImageCarousel;
