const globalState = {
  activeItem: localStorage.getItem("activeItem") || null, // Load the initial value from localStorage
  activeServiceItem: localStorage.getItem("activeServiceItem") || null, // Load the initial value from localStorage

  setActiveItem(item) {
    console.log("Setting active item:", item);
    this.activeItem = item;
    localStorage.setItem("activeItem", item); // Persist to localStorage
    this.notify(); // Notify listeners of the change
  },

  setActiveServiceItem(item) {
    console.log("Setting active SERVICE item:", item);
    this.activeServiceItem = item;
    localStorage.setItem("activeServiceItem", item); // Persist to localStorage
    this.notifyService(); // Notify service listeners of the change
  },

  listeners: [],
  listeners_Service: [],

  subscribe(listener) {
    this.listeners.push(listener); // Add listener to the listeners array
  },

  subscribe_Service(listener_Service) {
    this.listeners_Service.push(listener_Service); // Add listener to the service listeners array
  },

  notify() {
    this.listeners.forEach((listener) => listener(this.activeItem)); // Notify all listeners about the change
  },

  notifyService() {
    this.listeners_Service.forEach((listener_Service) =>
      listener_Service(this.activeServiceItem)
    ); // Notify all service listeners about the change
  },
};

export default globalState;
