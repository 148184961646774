import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import ServiceItem from "./components/ServiceItem";
import ServiceButton from "./components/ServiceButton";
import DevButton from "./components/DevButton"
import DevContentLoader from "./components/DevContentLoader";
import ImageList from "./components/Carousel";
import globalState from "./globalState";

import 'lazysizes';


import { useContext } from "react";



const contentLoaderRoot = ReactDOM.createRoot(document.getElementById('content-area'));

const loadContent = (url) => {
  console.log(`Loading content for URL: ${url}`); // Debugging line
  contentLoaderRoot.render(<DevContentLoader url={url} />);
  
};

// Function to handle hash changes and load content accordingly
const handleHashChange = () => {
  const anchor = window.location.hash.substring(1); // Get the hash value without the '#'
  if (anchor && anchor.includes("arendamine")) {
    loadContent(anchor);
    
  }
};

// Set up hash change listener
window.addEventListener('hashchange', handleHashChange);


// Function to render custom elements
const renderElement = (element) => {
  const tagName = element.tagName.toLowerCase();
  const title = element.getAttribute("data-title");
  const imgSrc = element.getAttribute("data-img-src");
  const root = ReactDOM.createRoot(element);

  if (tagName === "service-button") {
    const url = element.getAttribute("data-url");

    root.render(
      <ServiceButton
        title={title}
        imgSrc={imgSrc}
        url={url}
        parent_element={element}
      />
    );
  } else if (tagName === "dev-button") {
    const url = element.getAttribute("data-url");
    const xid = element.getAttribute("data-id");
    root.render(
      <DevButton
        title={title}
        imgSrc={imgSrc}
        xid={xid}
        url={url}
        parent_element={element}
        loadContent={loadContent} 
      />
    );
  } else if (tagName === "service-item") {
    const content = element.innerHTML;

    root.render(
      <ServiceItem title={title} imgSrc={imgSrc} parent_element={element}>
        {content}
      </ServiceItem>
    );
  }
};

// Observe when custom elements are added to the DOM
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node.nodeType === 1) {
        renderElement(node);
      }
    });
  });
});

observer.observe(document.body, {
  childList: true,
  subtree: true,
});


// History
const url="arendamine";

const hash=window.location.hash.substring(1);

if (hash.includes("arendamine")){
    loadContent(hash);
    globalState.setActiveItem(hash);
    
   
}
else {
  loadContent("arendamine_intro");
  globalState.setActiveItem("AAA");
  
}






// Initial render for existing elements
document
  .querySelectorAll("service-item, service-button, dev-button")
  .forEach(renderElement);



// populate carousel
const rootElement = document.getElementById("carousel");
const root = ReactDOM.createRoot(rootElement);
root.render (<ImageList></ImageList>)

