// DevContentLoader.js
import React, { useState, useEffect } from 'react';

function DevContentLoader({ url }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (url) {

      loadContent(url+".html");

    }
    const handleClick = () => {
      alert('React button clicked!');
    };
   
  }, [url]);


  

  const loadContent = async (url) => {
 
    try {
     
      const response = await fetch(url);
      const text = await response.text();
      setContent(text);

     
    } catch (error) {
      console.error('Error loading content:', error);
    }
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

export default DevContentLoader;
