import React, { useEffect, useState } from "react";
import globalState from "../globalState";

const ServiceButton = ({ title, imgSrc, url, parent_element }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const handleActiveItemChangeBtn = (activeItem) => {
      setActive(activeItem === url.substring(1));
      // Update the global state based on the new hash
      console.log(activeItem);
    };

    globalState.subscribe(handleActiveItemChangeBtn);

    return () => {
      globalState.listeners_Service = globalState.listeners_Service.filter(
        (listeners_Service) => listeners_Service !== handleActiveItemChangeBtn
      );
    };
  }, [url]);

  const setActiveB = () => {
    document.location.href = url;
    globalState.setActiveServiceItem(url.substring(1));
  };

  useEffect(() => {
    if (parent_element) {
      parent_element.className = "col-2 d-flex flex-column align-items-center";
    }
  }, [parent_element, url]);

  return (
    <div className="text-center"> 
      <div
        className="cursor-pointer  teenused-btn "
        style={{ display: "inline-block"}}
        onClick={setActiveB}
      >
        <img
          className="m-3 icon"
          src={imgSrc}
          width="45"
          height="45"
          alt={title}
          title={title}
          onClick={setActiveB}
          
        />
        <h4
          className={`teenused-text mx-4 teenus-col ${active ? "active-btn" : ""}` }
          id={url.substring(1) + "_btn"}
          onClick={setActiveB}
        >
          {title}
        </h4>
      </div>
    </div>
  );
};

export default ServiceButton;
