import React, { useState, useEffect } from "react";
import globalState from "../globalState";

const ServiceItem = ({ title, imgSrc, children, parent_element }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const handleActiveServiceItemChange = (activeServiceItem) => {
      setActive(activeServiceItem === parent_element.id);
     
      //console.log("ACTIVE:"+activeServiceItem);
    };

    globalState.subscribe_Service(handleActiveServiceItemChange);

    return () => {
      globalState.listeners = globalState.listeners.filter(
        (listener) => listener !== handleActiveServiceItemChange
      );
    };
  }, [parent_element.id]);

  useEffect(() => {
    if (parent_element) {
      parent_element.className = "col-12 col-xxxl-6";
    }
  }, [parent_element]);

  return (
    <article
      className={`service-content align-items-center mt-1 ${
        active ? "active-teenus" : ""
      }`}
    >
      <div
        className="row"
  

      >
        <div className="col-md overflow-hidden d-flex">
          <img
            className="service-thumbnail lazyload"
            data-src={imgSrc}
            alt={title}
            title={title}
            data-aos="zoom-out"
            data-aos-delay="400"
            data-aos-duration="400"
          />
        </div>
        <div className="col service-text p-4 " id={parent_element?.id + "-bg"}>
          <h3 className="service-title   ">{title}</h3>
          <hr
          className={` ${
            active ? "active-teenus-hr" : ""
          }`}></hr>
          <br />
          <div className="primary" dangerouslySetInnerHTML={{ __html: children }}></div>
        </div>
      </div>
    </article>
  );
};

export default ServiceItem;
