import React, { useEffect, useState } from "react";
import globalState from "../globalState";

const DevButton = ({ title, xid, imgSrc, url, parent_element, loadContent }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const handleActiveItemChangeBtn = (activeItem) => {
      setActive(activeItem === url);
      console.log("SHOULD NOT BE HERE");
    };

    // Subscribe to changes in globalState
    globalState.subscribe(handleActiveItemChangeBtn);

    // Set initial state based on globalState
    handleActiveItemChangeBtn(globalState.activeItem);

    return () => {
      // Cleanup subscription on component unmount
      globalState.unsubscribe(handleActiveItemChangeBtn);
    };
  }, [url]);

  const setActiveB = () => {
    //console.log(`Button clicked, URL: ${url}`); // Debugging line
    globalState.setActiveItem(url);
    document.location.href = "#" + url;

    if (loadContent) {
      //console.log(`Calling loadContent for URL: ${url}`); // Debugging line
      //loadContent(url); // Uncomment this if you want to call the loadContent function
    }
  };

  useEffect(() => {
    if (parent_element) {
      parent_element.className = "col d-flex flex-column align-items-center";
    }
  }, [parent_element]);

  return (
    <div className="text-center">
      <div
        className="cursor-pointer teenused-btn py-2"
        style={{ display: "inline-block" }}
        onClick={setActiveB}
      >
        <button
          type="button"
          className={`rounded-circle bg-light text-dark mx-auto bg-primary-hover border-0 d-block dev-circle ${
            active ? "active-step" : ""
          }`}
        >
          {xid}
        </button>
        <h4
          className={`teenused-text my-2 d-none d-md-block link-secondary ${
            active ? "active-btn" : ""
          }`}
          id={url.substring(1) + "_btn"}
          onClick={setActiveB}
        >
          {title}
        </h4>
      </div>
    </div>
  );
};

export default DevButton;
